import "../css/LandingPage.scoped.scss";
import { useNavigate } from "react-router-dom";

import Button from "../components/Buttons";
import { ReactComponent as Doughnut1 } from "../img/illustrations/doughnut-1.svg";
import { ReactComponent as Doughnut2 } from "../img/illustrations/doughnut-2.svg";
import { ReactComponent as CardImg1 } from "../img/illustrations/organized-illustration.svg";
import { ReactComponent as CardImg2 } from "../img/illustrations/sync-illustration.svg";
import { ReactComponent as CardImg3 } from "../img/illustrations/ballon-illustration.svg";


function CardContainer(props: { children: any }) {
  return (
    <div className="card-container">
      {props.children}
    </div>
  )
}

export default function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="landing-page">

      <div className="heading-container">
        <div className="illustration left">
          <Doughnut1 />
        </div>
        <div className="heading">

          <h1 className="blue">Forget about Forgetting!</h1>
          <h3>Quickly write it down for later!</h3>
          <div className="btn-box">
            <Button
              variant="B"
              size="normal"
              onClick={() => {
                navigate("/auth/signin");
              }}
            >
              Sign In
            </Button>
            <Button
              variant="A"
              size="normal"
              onClick={() => {
                navigate("/auth/signup");
              }}
            >
              Sign Up
            </Button>
          </div>
          <p><span className="blue"> Free</span> for everybody. Full Access. No Limits</p>
        </div>

        <div className="illustration right">
          <Doughnut2 />
        </div>
      </div>

      <div className="seperator"></div>

      <div className="card-layout">
        <CardContainer>
          <CardImg1 />
          <p className="card-text">Simple and Quick Input</p>
        </CardContainer>
        <CardContainer>
          <CardImg2 />
          <p className="card-text">Synced Across your devices</p>
        </CardContainer>
        <CardContainer>
          <CardImg3 />
          <p className="card-text">Acces Anytime, Anywhere</p>
        </CardContainer>
      </div>
    </div>
  );
}
