import "../css/Buttons.scoped.scss";

export default function Button(props: {
  variant: "A" | "B" | "C";
  size: "small" | "normal";
  onClick: any;
  children: any;
}) {
  return (
    <button
      className={`btn-base text-bg
        btn-variant-${props.variant}
        btn-size-${props.size}
      `}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
