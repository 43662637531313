import "../css/AuthPage.scoped.scss";
import "../css/Buttons.scoped.scss";
import { useState } from "react";
import { uniqueId } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import {
  signUpUser,
  loginUser,
  LoginError,
  SignUpError,
} from "../js/fbManager";
import { ReactComponent as Doughnut1 } from "../img/illustrations/doughnut-1.svg";
import { ReactComponent as Doughnut2 } from "../img/illustrations/doughnut-2.svg";

export type Mode = "signup" | "signin";


function InputField(props: {
  getRef: any;
  name: string;
  info: string;
  placeholder: string;
  type: "email" | "password";
  minLength?: number;
  maxLength?: number;
  onChange?: (value: string) => void
}) {
  const id = uniqueId();

  return (<div className="input-field">
    <label className="name-label" htmlFor={id}>{props.name}</label>
    <input
      ref={props.getRef}
      type={props.type}
      id={id}
      placeholder={props.placeholder}
      minLength={props.minLength}
      maxLength={props.maxLength}
      required
      onChange={(event: any) => {
        if (props.onChange) {
          props.onChange(event.target.value);
        }
      }}
    />
    <label className="info-label" htmlFor={id}>{props.info}</label>
  </div>)
}


function EmailInput(props: {
  getRef: any;
  onChange?: (value: string) => void
}) {
  return (
    <InputField
      getRef={props.getRef}
      name="E-Mail"
      info=""
      placeholder="jenny@gmail.com"
      type="email"
      maxLength={256}
      onChange={props.onChange}
    />
  )
}


function PasswordInput(props: {
  getRef: any;
  name: string;
  onChange?: (value: string) => void
}) {
  return (
    <InputField
      getRef={props.getRef}
      name={props.name}
      info=""
      placeholder=""
      type="password"
      minLength={8}
      maxLength={128}
      onChange={props.onChange}
    />
  )
}


function SignInForm() {
  let emailRef: any;
  let passwordRef: any;

  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleSignIn = function (event: any) {
    event.preventDefault();
    loginUser(emailRef.value, passwordRef.value)
      .then(() => {
        navigate("/notes", { replace: true });
      })
      .catch((error: LoginError) => {
        setErrMsg(error.getUserMessage());
      });
  }

  return (
    <>
      <h2>Sign-In</h2>
      <p className="error">{errMsg}</p>
      <form onSubmit={handleSignIn}>
        <div className="inputs">
          <EmailInput
            getRef={(ref: any) => {
              emailRef = ref;
            }}
          />
          <PasswordInput
            name="Password"
            getRef={(ref: any) => {
              passwordRef = ref;
            }}
          />
        </div>
        <div className="center">
          <input className="btn-base" type="submit" value="Sign In" />
        </div>
      </form>
      <div className="center">
        <Link to="/auth/signup">Go to Sign up</Link>
      </div>
    </>
  )
}

function SignUpForm() {
  let emailRef: any;
  let passwordRef: any;
  let passwordVerifyRef: any;

  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleSignUp = function (event: any) {
    event.preventDefault();
    signUpUser(
      emailRef.value,
      passwordRef.value,
      passwordVerifyRef.value
    )
      .then(() => {
        navigate("/notes", { replace: true });
      })
      .catch((error: SignUpError) => {
        setErrMsg(error.getUserMessage());
      });
  }

  return (
    <>
      <h2>Sign Up</h2>
      <p className="error">{errMsg}</p>
      <form onSubmit={handleSignUp}>
        <div className="inputs">
          <EmailInput
            getRef={(ref: any) => {
              emailRef = ref;
            }}
          />
          <PasswordInput
            name="Password"
            getRef={(ref: any) => {
              passwordRef = ref;
            }}
          />
          <PasswordInput
            name="Verify Password"
            getRef={(ref: any) => {
              passwordVerifyRef = ref;
            }}
          />
        </div>
        <div className="center">
          <input className="btn-base" type="submit" value="Sign Up" />
        </div>
      </form>
      <div className="center">
        <Link to="/auth/signin">Go to Sign-In</Link>
      </div>
    </>
  )
}

export default function AuthPage(props: { mode: Mode }) {

  return (
    <div className="page-layout">
      <div className="svg-container">
        <Doughnut1 />
      </div>
      <div className="form-container">
        {
          props.mode === "signin" ?
            <SignInForm /> : <SignUpForm />
        }
      </div>
      <div className="svg-container">
        <Doughnut2 />
      </div>

    </div>
  );
}
