import "./css/App.scoped.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import LandingPage from "./pages/LandingPage";
import NotesPage from "./pages/NotesPage";
import AuthPage from "./pages/AuthPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />

        <article>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/notes" element={<NotesPage />} />
            <Route path="/auth/signin" element={<AuthPage mode="signin" />} />
            <Route path="/auth/signup" element={<AuthPage mode="signup" />} />
            <Route
              path="*"
              element={
                <div className="page-404">
                  <h1>404 Page not found</h1>
                </div>
              }
            />
          </Routes>
        </article>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
