import { useEffect, useState } from "react";
import "../css/AccountToast.scoped.scss";
import { ReactComponent as AccountIcon } from "../img/account-icon.svg";
import { ReactComponent as CloseIcon } from "../img/close-icon.svg";
import { ReactComponent as VerifiedSvg } from "../img/verified-icon.svg";
import { ReactComponent as NotVerifiedSvg } from "../img/not-verified-icon.svg";
import Button from "./Buttons";
import {
  emailIsVerified,
  getUserData,
  logoutUser,
  onSignInChanged,
  sendEmailVerificationEmail,
} from "../js/fbManager";

function VerifeidIcon(props: { verified: boolean }) {
  return (
    <div title={props.verified ? "Email verifed" : "Email not verifed"}>
      {props.verified ? <VerifiedSvg /> : <NotVerifiedSvg />}
    </div>
  )
}

export default function AccountToast() {
  const [isOpen, setOpen] = useState(false);
  const [transitionOut, setTransitionOut] = useState(false);
  const [isSignedIn, setSignInStatus] = useState(false);
  const [message, setMsg] = useState("");

  const transitionOutTime = 250; //ms

  const open = () => {
    setOpen(true);
    setTransitionOut(false);
  }
  const close = () => {
    // setOpen(false);
    setTransitionOut(true);
    setTimeout(() => {
      setOpen(false)
    }, transitionOutTime)
  }

  useEffect(() => {
    return onSignInChanged((signedIn: boolean) => {
      setSignInStatus(signedIn);
    })
  })

  return (
    <div className={`${isSignedIn ? "" : "hide"}`}>
      <div
        className="account-icon"
        onClick={() => {
          open();
        }}
      >
        <AccountIcon />
      </div>
      <div className={`
        toast-origin
        ${isOpen ? "" : "hide"}
      `}>
        <div className={`
          anim-container
          ${isOpen && !transitionOut ? "transition-in" : ""}
          ${transitionOut ? "transition-out" : ""}
        `}>
          <div className="title">
            <div
              className="close-icon"
              onClick={() => {
                close();
              }}
            >
              <CloseIcon />
            </div>
            <h3>Account</h3>
          </div>

          <div className="email">

            <VerifeidIcon verified={emailIsVerified()} />
            <p>{getUserData()?.email}</p>
          </div>

          <div className="buttons">
            <Button
              variant="A"
              size="small"
              onClick={() => {
                logoutUser();
              }}
            >Sign Out</Button>
            {
              emailIsVerified() === false &&
              <Button
                variant="B"
                size="small"
                onClick={() => {

                  sendEmailVerificationEmail()
                    .then(() => {
                      setMsg("Check your E-Mails and reload the page")
                    })
                    .catch((err) => {
                      setMsg("Didn't work. Try again!")
                    });

                }}
              >Verify Email</Button>
            }
          </div>
          <p className="message">
            {message}
          </p>
        </div>
      </div>
    </div >
  );
}
