import "../css/Header.scoped.scss";
import { ReactComponent as Logo } from "../img/logo-white-bg.svg";
import { Link } from "react-router-dom";
import { onSignInChanged } from "../js/fbManager";
import { useState } from "react";
import AccountToast from "./AccountToast";

export default function Header() {
  const [signedIn, setSignedIn] = useState(false);

  onSignInChanged((isSignedIn: boolean): void => {
    setSignedIn(isSignedIn);
  });

  return (
    <header>
      <Link to="/">
        <div className="logo">
          <Logo />
          <p className="text-xl">Notely</p>
        </div>
      </Link>
      <div className="links">

        <Link to="/">
          <p className="text-sm">Home</p>
        </Link>
        <Link to="/notes">
          <p className="text-sm">My Notes</p>
        </Link>
        {signedIn &&
          // eslint-disable-next-line
          <a>
            <AccountToast />
          </a>
        }
      </div>
    </header >
  );
}
