import "../css/NotesPage.scoped.scss";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createNotesListener,
  updateNote,
  Note,
  createTextNote,
  createTodoNote,
  deleteNote,
  TextContent,
  TodoContent,
  TodoItem,
  createEmptyTodoItem,
  onSignInChanged,
} from "../js/fbManager";
import Button from "../components/Buttons";
import Dropdown from "react-bootstrap/Dropdown";
import { cloneDeep, uniqueId } from "lodash";

import { ReactComponent as EditIcon } from "../img/dots-hor.svg"
import LoadingIcon from "../components/LoadingIcon";

function StringEditor(props: {
  defaultValue: string;
  placeholder: string;
  isMultiline: boolean;
  isSmall?: boolean;
  onValueChange: (before: string, after: string) => void;
}) {
  const isSmall = props.isSmall ?? false;

  const [valueBefore, setBefore] = useState(props.defaultValue);
  const refSingle: any = useRef();
  const refMulti: any = useRef();

  const ref: any = props.isMultiline ? refMulti : refSingle;

  const onFocus = () => {
    setBefore(ref.current.value);
  };

  const onBlur = () => {
    const valueNew = ref.current.value;
    if (valueNew !== valueBefore) {
      if (props.onValueChange) {
        props.onValueChange(valueBefore, valueNew);
      }
    }
  };

  const setRowsIfNeeded = () => {
    // https://stackoverflow.com/questions/17772260/textarea-auto-height
    ref.current.style.height = "5px"; // else won't shrink
    ref.current.style.height = ref.current.scrollHeight + "px";
  };

  // update initial numer of rows
  useEffect(() => {
    if (props.isMultiline === true) {
      setRowsIfNeeded();
    }
  });

  useEffect(() => {
    ref.current.value = props.defaultValue;
  }, [ref, props.defaultValue]);

  if (props.isMultiline === true) {
    return (
      <div className="editor-border">
        <textarea
          className="editor"
          ref={refMulti}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={setRowsIfNeeded}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        />
      </div>
    );
  } else {
    return (
      <div className={`editor-border ${isSmall ? "editor-border-small" : ""}`}>
        <input
          className={`editor ${isSmall ? "editor-small" : ""} `}
          ref={refSingle}
          onFocus={onFocus}
          onBlur={onBlur}
          type="text"
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        />
      </div>
    );
  }
}

function TodoItemEditor(props: {
  item: TodoItem;
  hideDoneButton: boolean;
  onValueChange: (newItem: TodoItem) => void
  onDeleteItem: () => void;
}) {

  const item = props.item ?? createEmptyTodoItem();

  return (
    <div className="todo-item">
      <StringEditor
        defaultValue={item.text}
        placeholder="what needs to be done?"
        isMultiline={false}
        isSmall={true}
        onValueChange={(before: string, after: string): void => {
          let newItem;
          if (props.item) {
            newItem = cloneDeep(props.item);
          } else {
            newItem = createEmptyTodoItem();
          }
          newItem.text = after;
          props.onValueChange(newItem)
        }}
      />

      {props.hideDoneButton && <Button
        variant="C"
        size="small"
        onClick={() => { props.onDeleteItem(); }}
      >Done</Button>}
    </div>
  );
}

function TodoEditor(props: {
  items: Array<TodoItem>;
  onValueChange: (newItems: Array<TodoItem>) => void;
}) {

  const handleValueChange = (index: number, newItem: TodoItem) => {
    const newItems = [...props.items] // copy
    newItems[index] = newItem;
    props.onValueChange(newItems);
  };

  const items = [...props.items, createEmptyTodoItem()];

  return (
    <div>
      {
        items.map((item: TodoItem, index: number) => {
          return (
            <TodoItemEditor
              item={item}
              key={index}
              hideDoneButton={(index + 1) < items.length}
              onValueChange={(newItem: TodoItem) => {
                handleValueChange(index, newItem)
              }}
              onDeleteItem={() => {
                const newItems = [...props.items] // copy
                newItems.splice(index, 1);
                props.onValueChange(newItems);
              }}
            />
          );
        })
      }
    </div>
  );
}

type EditAction = "delete" | "change-color";

function NoteEditorDropdown(props: {
  callback: (action: EditAction, data: any) => void;
}) {
  const dataMap: any = {
    delete: ["delete", null],
    colorRed: ["change-color", "red"],
    colorGreen: ["change-color", "green"],
    colorBlue: ["change-color", "blue"],
    colorYellow: ["change-color", "yellow"],
  };

  const handleSelect = (eventKey: any, event: any): any => {
    const key: string = eventKey ?? "";

    if (eventKey) {
      if (Object.keys(dataMap).includes(key)) {
        const [action, data] = dataMap[key];
        props.callback(action as EditAction, data);
      } else {
        console.error("Dropdown: unknown event key");
      }
    } else {
      console.error("Dropdown: no event key specified");
    }
  };

  return (
    <div className="edit-btn-container">
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle className="dropdown-toggle-override" id={uniqueId()}>
          <EditIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>Actions</Dropdown.Header>
          <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>

          <Dropdown.Header>Colors</Dropdown.Header>
          <Dropdown.Item eventKey="colorRed" >Red</Dropdown.Item>
          <Dropdown.Item eventKey="colorGreen" >Green</Dropdown.Item>
          <Dropdown.Item eventKey="colorBlue" >Blue</Dropdown.Item>
          <Dropdown.Item eventKey="colorYellow" >Yellow</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

function NoteEditor(props: {
  data: Note;
  onNoteChange: (newNote: Note) => void;
  onNoteDelete: (note: Note) => void;
}) {
  return (
    <div className={`note note-color-${props.data.color}`}>
      <NoteEditorDropdown
        callback={(action, data) => {
          console.info("NoteEditAction:", action, "Data:", data);

          switch (action) {
            case "delete":
              props.onNoteDelete(props.data);
              break;
            case "change-color":
              const newNote = Object.assign({}, props.data);
              newNote.color = data;
              props.onNoteChange(newNote);
              break;
          }
        }}
      />

      {/* Title */}
      <StringEditor
        defaultValue={props.data.title}
        placeholder="Enter Title"
        isMultiline={false}
        onValueChange={(from, to) => {
          const newNote = Object.assign({}, props.data);
          newNote.title = to;
          props.onNoteChange(newNote);
        }}
      />

      {props.data.contentType === "text" && (
        <StringEditor
          defaultValue={(props.data.content as TextContent).text}
          placeholder="What should I remember for you?"
          isMultiline={true}
          onValueChange={(from, to) => {
            const newNote = Object.assign({}, props.data);
            (newNote.content as TextContent).text = to;
            props.onNoteChange(newNote);
          }}
        />
      )}

      {props.data.contentType === "todo" && (
        <TodoEditor
          items={(props.data.content as TodoContent).items}
          onValueChange={(newItems: Array<TodoItem>) => {
            const newNote = Object.assign({}, props.data);
            (newNote.content as TodoContent).items = newItems;
            props.onNoteChange(newNote);
          }}
        />
      )}
    </div>
  );
}

interface IProps {
  navigate: any;
}
interface IState {
  notes: Array<Note>;
  notesNotUpdatedYet: boolean;
  numberOfColumns: number;
  signedIn: boolean;
}

class NotesPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      notes: [],
      notesNotUpdatedYet: true,
      numberOfColumns: 3,
      signedIn: false,
    };

    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handleNoteDelete = this.handleNoteDelete.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onSignInStateChanged = this.onSignInStateChanged.bind(this);
  }

  unsubFromNotesListener: any = null;
  unsubFromSignInChange: any = null;

  componentDidMount() {
    this.setupNotesListener();
    this.unsubFromSignInChange = onSignInChanged(this.onSignInStateChanged);

    window.addEventListener("resize", this.onResize);
    // call once to setup initial state for the current screen size
    this.setupNumberOfColumns();
  }

  componentWillUnmount() {
    console.log("unmount");
    window.removeEventListener("resize", this.onResize);

    this.removeNotesListener();

    // TODO: Test unsubFromSignInChange. Might not even work
    if (this.unsubFromSignInChange)
      this.unsubFromSignInChange();
  }

  onResize(event: any): void {
    this.setupNumberOfColumns()
  }

  onSignInStateChanged(isSignedIn: boolean): void {
    this.setState({ signedIn: isSignedIn });
    if (isSignedIn) {
      this.setupNotesListener();
    } else {
      this.removeNotesListener();
    }
  }

  setupNotesListener(): void {
    if (this.unsubFromNotesListener === null) {
      createNotesListener((notes) => {
        this.setState(() => {
          return { notes: notes, notesNotUpdatedYet: false };
        });
      }).then((unsub) => {
        this.unsubFromNotesListener = unsub;
      });
    } else {
      console.warn("unsubFromNotesListener() isn't null, won't setup a possible secound one")
    }
  }

  async removeNotesListener(): Promise<void> {
    // TODO: Test unsubFromNotesListener. Might not even work
    if (this.unsubFromNotesListener !== null) {
      console.log("remove notes listener");
      await this.unsubFromNotesListener();
      this.unsubFromNotesListener = null;
    }
  }

  setupNumberOfColumns(): void {
    if (window.innerWidth < 1000 && window.innerWidth >= 500) {
      this.changeNumberOfColumns(2);
    } else if (window.innerWidth < 500) {
      this.changeNumberOfColumns(1);
    } else {
      this.changeNumberOfColumns(3)
    }
  }

  handleNoteChange(newNote: Note) {
    console.log("handleNoteChange:", newNote);
    updateNote(newNote).catch((error) => console.error);
  }

  handleNoteDelete(note: Note) {
    deleteNote(note);
  }

  getNotesOfColumn(colIndex: number, numberOfColumns: number): Array<Note> {
    const res: Array<Note> = [];
    this.state.notes.forEach((note: Note, i: number): void => {
      if ((i % numberOfColumns) === colIndex) {
        res.push(note);
      }
    });
    return res;
  }

  changeNumberOfColumns(numberOfColumns: number): void {
    this.setState({ numberOfColumns: numberOfColumns });
  }

  iterateColumns(fn: (index: number) => any): any {
    const arr: Array<any> = [];
    for (let i = 0; i < this.state.numberOfColumns; i++) {
      arr.push(fn(i));
    }
    return arr;
  }

  render() {
    if (this.state.signedIn) {

      return (
        <div className="notes-page">
          <div className="button-container">
            <Button
              variant="A"
              size="normal"
              onClick={() => {
                createTextNote("", "");
              }}
            >
              Add Note
            </Button>
            <Button
              variant="A"
              size="normal"
              onClick={() => {
                createTodoNote("", []);
              }}
            >
              Add Todo
            </Button>
          </div>

          <div className="notes-table">
            {
              this.state.notesNotUpdatedYet &&
              <LoadingIcon text="Loading Notes ..." />
            }
            {
              this.state.notes.length === 0 && !this.state.notesNotUpdatedYet &&
              <h3 className="no-notes-notice">Click the "Add Note" button to create a new Note</h3>
            }
            <div className="notes-column">
              {this.iterateColumns((index: number): any => {
                const notes: any = this.getNotesOfColumn(index, this.state.numberOfColumns);

                return (
                  <div className="notes-container" key={index}>
                    {
                      notes.map((note: Note, i: number) => {
                        return (
                          <NoteEditor
                            key={note.id}
                            data={note}
                            onNoteChange={this.handleNoteChange}
                            onNoteDelete={this.handleNoteDelete}
                          />
                        );
                      })
                    }
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="not-signed-in-page">
          <h2>Sign In first</h2>
          <div className="btn-box">
            <Button
              variant="B"
              size="normal"
              onClick={() => {
                this.props.navigate("/auth/signin");
              }}
            >
              Sign In
            </Button>
            <Button
              variant="A"
              size="normal"
              onClick={() => {
                this.props.navigate("/auth/signup");
              }}
            >
              Sign Up
            </Button>
          </div>
        </div>
      )
    }
  }
}

// apperently useNavigate does not have a class alternative. This is ridiculous
function NotesPageWrapper() {
  const navigate = useNavigate();
  return (
    <NotesPage navigate={navigate} />
  )
}

export default NotesPageWrapper