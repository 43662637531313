import "../css/LoadingIcon.scoped.scss";

export default function LoadingIcon(props: { text?: string }) {

  return (
    <div className="icon-container">
      <svg height="60" width="60" viewBox="-30 -30 60 60" >
        <circle transform="rotate(45, -10, -10) translate(5, 0)" cx="-10" cy="-10" r="15.9" stroke="red" strokeWidth="2" fill="transparent" />
        <circle transform="rotate(-45, -10, 10) translate(5, 0)" cx="-10" cy="10" r="15.9" stroke="green" strokeWidth="2" fill="transparent" />
        <circle transform="rotate(135, 10, -10) translate(5, 0)" cx="10" cy="-10" r="15.9" stroke="blue" strokeWidth="2" fill="transparent" />
        <circle transform="rotate(225, 10, 10)  translate(5, 0)" cx="10" cy="10" r="15.9" stroke="orange" strokeWidth="2" fill="transparent" />
        {/* <circle r="2" fill="pink" /> */}
      </svg>
      {props.text && <p>{props.text}</p>}
    </div >
  );
}
