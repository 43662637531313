import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  // connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  UserCredential,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  User,
  signOut,
  sendEmailVerification,
  // connectAuthEmulator,
} from "firebase/auth";
import { FIREBASE_CONFIG } from "./secrets";


const app = initializeApp(FIREBASE_CONFIG);
const db = getFirestore(app);
const auth = getAuth();

// connect to the emulator
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectAuthEmulator(auth, "http://localhost:9099");


/* Increment on use */
let nextNotePosition: number = -1;

/* Don't forget to set the default in 'contentDefaulValues' */
type ContentType = "text" | "todo";
type TodoItem = { text: string; isDone: boolean }
type Content = TextContent | TodoContent;
type TextContent = {
  text: string;
};
type TodoContent = {
  items: Array<TodoItem>
};
/* Don't forget to set the default in 'noteDefaultValues' */
type Note = {
  id?: string;
  title: string;
  contentType: ContentType;
  content: Content;
  color: string;
  position: number;
};

/* Defines the default values for the note properties */
const noteDefaultValues = {
  "title": "",
  "contentType": "text",
  "color": "green",
  "position": -1,
  "content": "", // empyt-string indicates not set. object values will be set in a subsequent step
}
/* Defines the default values for the note.content properties */
/* key === contentType */
const contentDefaulValues = {
  "text": { text: "" },
  "todo": { items: "" }
}


type signUpErrorCode =
  "auth/email-already-in-use" |
  "auth/invalid-email" |
  "auth/operation-not-allowed" |
  "auth/weak-password" |
  "password-missmatch" |
  "already-logged-in";
type loginErrorCode =
  "auth/invalid-email" |
  "auth/user-disabled" |
  "auth/user-not-found" |
  "auth/wrong-password" |
  "already-logged-in";


const errorCodeMap = {
  "auth/weak-password": "The password must be at least 8 characters",
  "auth/wrong-password": "Wrong Password 🙁",
  "auth/invalid-email": "Email is not valid",
  "auth/email-already-in-use": "Email is already used",
  "auth/user-disabled": "Account is disabled",
  "auth/user-not-found": "Account does not exist. Sign-Up first?",
  "auth/operation-not-allowed": "Operation not allowed",
  "password-missmatch": "The entered passwords are not the same",
  "already-logged-in": "Theres already an account logged-in. "
}


class AuthError extends Error { }
class SignUpError extends Error {
  constructor(message: string, code: signUpErrorCode) {
    super(message);
    this.code = code;
  }

  code: signUpErrorCode;

  getUserMessage() {
    if (Object.keys(errorCodeMap).includes(this.code)) {
      return errorCodeMap[this.code];
    } else {
      console.error("Unkown signUpErrorCode!");
      return "Error: " + this.code;
    }
  }
}
class LoginError extends Error {
  constructor(message: string, code: loginErrorCode) {
    super(message);
    this.code = code;
  }

  code: loginErrorCode;

  getUserMessage() {
    if (Object.keys(errorCodeMap).includes(this.code)) {
      return errorCodeMap[this.code];
    } else {
      console.error("Unkown loginErrorCode!");
      return "Error: " + this.code;
    }
  }
}

/* Will create a new note in the users database */
function createNote(title: string, content: Content, contentType: ContentType, color: string) {
  return new Promise<void>((resolve, reject) => {
    const user = auth.currentUser

    if (user) {
      addDoc(collection(db, "users", user.uid, "notes"), {
        title: title,
        content: content,
        contentType: contentType,
        color: color,
        position: nextNotePosition++
      })
        .then((ref) => {
          resolve();
          console.info("create new note with id: ", ref.id);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject(new AuthError("User not signed in. Can not create a new note"));
    }
  });
}

/* Will create a text note in the users database */
function createTextNote(title: string, text: string) {
  return createNote(title, { text: text }, "text", "green");
}

/* Will create a todo note in the users database */
function createTodoNote(title: string, items: Array<TodoItem>) {
  return createNote(title, { items: items }, "todo", "green");
}

function createEmptyTodoItem() {
  return Object.assign({}, { text: "", isDone: false });
}

function registerUserNotesListner(user: User, callback: (notes: Array<Note>) => any) {
  return onSnapshot(collection(db, "users", user.uid, "notes"), (snapshot) => {
    const notes: Array<Note> = [];
    snapshot.forEach((d) => {
      const note = d.data();
      note["id"] = d.id;
      notes.push(note as Note);
    });
    callback(notes);
  });
}

function validateObject(obj: any, defaultValues: any): any {
  const objKeys = Object.keys(obj);
  const expectedKeys: Array<string> = Object.keys(defaultValues);

  expectedKeys.forEach(key => {
    if (objKeys.includes(key) === false) {
      console.info("Did not include key: ", key);
      obj[key] = defaultValues[key];
    }
  });
  return obj
}

function validateNote(note: Note): Note {
  note = validateObject(note, noteDefaultValues);
  if ((note.content as any) === "") {
    (note.content as any) = {};
  }
  note.content = validateObject(note.content, contentDefaulValues[note.contentType]);
  return note
}

function getBiggestNotePosition(notes: Array<Note>): number {
  return Math.max(-1, ...notes.map((note: Note): number => {
    return note.position;
  }));
}

function orderNotes(notes: Array<Note>): Array<Note> {
  return notes.sort((a: Note, b: Note): number => {
    return b.position - a.position; // sort descending
  })
}

function validateNotes(notes: Array<Note>): Array<Note> {
  return notes.map((note: Note, i: number): Note => {
    return validateNote(note);
  });
}


/* Will setup a listener on the user notes collection
 *    callback: receives an array with all the users notes
 *    returns:  a function to remove the listener */
function createNotesListener(callback: (notes: Array<Note>) => any) {
  return new Promise<any>((resolve, reject) => {
    const user = auth.currentUser

    const cb = (notes: Array<Note>) => {
      let valid = validateNotes(notes)
      valid = orderNotes(valid);
      nextNotePosition = getBiggestNotePosition(valid) + 1;
      return callback(valid)
    }

    if (user) {
      resolve(
        registerUserNotesListner(user, cb)
      );
    } else {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(
            registerUserNotesListner(user, cb)
          );
        }
      });
    }

  })
}

function updateNote(note: Note) {
  return new Promise<void>((resolve, reject) => {
    if (userIsSignedIn() === false) {
      reject(new AuthError("User not signed in. Can not udpate note"))
    } else {
      // don't save the notes id in the document
      const id: string = note.id as string;
      delete note.id;
      updateDoc(doc(db, "users", (auth.currentUser as User).uid, "notes", id), note)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

function deleteNote(note: Note) {
  return new Promise<void>((resolve, reject) => {
    if (userIsSignedIn() === false) {
      reject(new AuthError("User not signed in. Can not delete note"))
    } else {
      const id: string = note.id as string;
      deleteDoc(doc(db, "users", (auth.currentUser as User).uid, "notes", id))
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}


function signUpUser(email: string, password: string, verifyPassword: string) {
  return new Promise<UserCredential>((resolve, reject) => {
    if (auth.currentUser !== null) {
      reject(new SignUpError("logout current user first", "already-logged-in"));
    }
    // TODO: better verification of passwords
    else if (password !== verifyPassword) {
      reject(new SignUpError("password input isn't equal to the verify password input", "password-missmatch"));
    }
    else if (password.length < 8) {
      reject(new SignUpError("password not of legth 8", "auth/weak-password"));
    }
    else {
      createUserWithEmailAndPassword(getAuth(), email, password)
        .then((userCredential: UserCredential) => {
          resolve(userCredential);
          sendEmailVerificationEmail().then(() => {
            console.info("verification mail send")
          }).catch((err) => {
            console.error("verificaiton mail did not work:")
            console.error(err)
          });
        })
        .catch((error) => {
          reject(new SignUpError(error.message, error.code))
        });
    }
  });
}

function loginUser(email: string, password: string): Promise<User> {
  return new Promise<User>((resolve, reject) => {
    if (auth.currentUser !== null) {
      reject(new LoginError("logout current user first", "already-logged-in"));
    }
    else {
      signInWithEmailAndPassword(getAuth(), email, password)
        .then((userCredential: UserCredential) => {
          resolve(userCredential.user);
        })
        .catch((error) => {
          reject(new LoginError(error.message, error.code));
        });
    }
  });
}

function loginUserIfNeeded(email: string, password: string): Promise<User> {
  return new Promise<User>((resolve, reject) => {
    if (auth.currentUser !== null) {
      resolve(auth.currentUser);
    } else {
      loginUser(email, password)
        .then((userCred) => {
          resolve(userCred);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

/* Logout the current user */
function logoutUser(): Promise<void> {
  return signOut(auth);
}

function userIsSignedIn(): boolean {
  return auth.currentUser !== null;
}

function sendEmailVerificationEmail(): Promise<void> {
  console.info("Sending an email verfication mail");

  return new Promise<void>((resolve, reject) => {
    if (userIsSignedIn()) {
      if (auth.currentUser?.emailVerified === false) {
        sendEmailVerification(auth.currentUser)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject("Email already verified");
      }
    } else {
      reject("User not signed in");
    }
  });
}

function emailIsVerified(): boolean {
  if (userIsSignedIn()) {
    return auth.currentUser?.emailVerified as boolean;
  } else {
    return false;
  }
}

/* callback will be called when the sign-in status changes
 * returns a function to unsubscribe */
function onSignInChanged(callback: (isSignedIn: boolean) => void): any {
  return onAuthStateChanged(auth, (user) => {
    callback(user !== null);
  });
}

type UserData = {
  email: string;
}

function getUserData(): UserData | null {
  const user = auth.currentUser;
  if (user) {
    return {
      email: user.email ?? "",
    }
  } else {
    return null;
  }
}


export type {
  Note,
  TodoItem,
  SignUpError,
  LoginError,
  ContentType,
  Content,
  TextContent,
  TodoContent,
  UserData,
};
export {
  createTextNote,
  createTodoNote,
  createEmptyTodoItem,
  updateNote,
  deleteNote,
  createNotesListener,
  signUpUser,
  loginUser,
  loginUserIfNeeded,
  logoutUser,
  userIsSignedIn,
  onSignInChanged,
  emailIsVerified,
  sendEmailVerificationEmail,
  getUserData,
};
