const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAYJDzW1KMiuYKpCxg7wQLmrOngV08IYok",
  authDomain: "notes-app-35d9b.firebaseapp.com",
  projectId: "notes-app-35d9b",
  storageBucket: "notes-app-35d9b.appspot.com",
  messagingSenderId: "927050141696",
  appId: "1:927050141696:web:746475bd671a9abdec88e5",
  measurementId: "G-RF397PSK64",
};

export { FIREBASE_CONFIG };
